import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col, Carousel } from 'react-bootstrap';

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Home" />
    <div className="light-gold-bg pb-4">
      <Container>
        <Row className="justify-content-center">
          <Col xl={4} lg={6} className="d-flex text-center text-lg-start align-items-center">
            <div>
              <h2>“A Walk along the forgotten History footpaths of the City”</h2>
              <p className="lead">
              Learn about Urban slavery, the Slave Trade and the Cotton Trade in Savannah.
		See the remnants of the 1860s slave trade and a building that once was a slave Mart that became a Freedmen’s School. 
              </p>
            </div>
          </Col>
          <Col xl={5} lg={6} className="text-center text-lg-start">
          <StaticImage
              src="../images/vaughnette.jpg"
              loading="eager"
              width={650}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Footprints Walking Tour"
              className="rounded"
            />
          </Col>
        </Row>
      </Container>      
    </div>

    <Container className="singleCol mt-3">
      <p>
      Conversation, questions and comments about the History are encouraged along the 90 minute Walk. Limited to four to six guests until further notice. Private Tours available for an additional fee. 
      </p>

      <p className="text-center">
      Footprints of Savannah Walking Tour Fee accepted at the end of the Walk. 
      </p>
      <p className="text-center">
      Adults $30 per person (Gratuity not included) <br />
				 Children under 12 years old ($10)
      </p>
      
      <p className="text-center">
      Tours are reserved by phone only and confirmed by texts.<br /><strong>912-695-3872</strong><br /> Based on availability. Tours available on Sundays currently.  
		    
      </p>

      <Carousel variant="dark" interval="10000" className="mb-3">
        {data.slideShowPictures.nodes.map(node => (
          <Carousel.Item key={node.id}>
            <GatsbyImage image={getImage(node.childImageSharp)} alt={node.base.split('-').join(' ').split('.')[0]}  />
          </Carousel.Item>
        ))}
      </Carousel>
      <hr />
      <h3>Footprints of Savannah Virtual Tours</h3>

      <p>
        For more information and to book reservations call (912) 695-3872. Virtual Tour Fee: $15 per person. Pay online after booking. Virtual Tour reservations only. 
                 Email: <a href="mailto:sistavandhistory@gmail.com">sistavandhistory@gmail.com</a> to receive a Zoom link for the Virtual Tour. 
      </p>
    </Container>
      
      
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Walk through Savannah's History." />

export default IndexPage

export const query = graphql`
  query {
    slideShowPictures: allFile(
      filter: {relativePath: {glob: "slide-show/*"}}
      sort: {fields: base, order: ASC}
    ) {
      nodes {
        relativePath
        id
        childImageSharp {
          gatsbyImageData(
            transformOptions: {fit: CONTAIN}
            width: 750
            quality: 70
            placeholder: BLURRED
            jpgOptions: {progressive: true, quality: 70}
            webpOptions: {quality: 50}
            blurredOptions: {toFormat: JPG, width: 50}
            height: 500
            backgroundColor: "#ffffff"
          )
        }
        base
      }
    }
  }
`;